const cloud = {
    general: {
        onboarding: 'Integração',
    },
    create_tenant: {
        page_title: 'Criar inquilino',
        title: 'Crie seu primeiro inquilino',
        description: 'Um inquilino é um ambiente isolado onde você pode gerenciar identidades de usuário, aplicações e todos os outros recursos do Logto.',
        invite_collaborators: 'Convide seus colaboradores por e-mail',
    },
    social_callback: {
        title: 'Você entrou com sucesso',
        description: 'Você entrou com sucesso usando sua conta social. Para garantir a integração perfeita e o acesso a todos os recursos do Logto, recomendamos que você prossiga para configurar seu próprio conector social.',
        /** UNTRANSLATED */
        notice: "Please avoid using the demo connector for production purposes. Once you've completed testing, kindly delete the demo connector and set up your own connector with your credentials.",
    },
    tenant: {
        create_tenant: 'Criar inquilino',
    },
};
export default Object.freeze(cloud);
